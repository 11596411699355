
@import '../../../shared-global-styles.module.scss';

.loader {
  border: 10px solid $brightPink2Fade;
  border-top: 10px solid $brightPink1;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.25s linear infinite;
  transition: opacity .75s ease-out;
  -moz-transition: opacity .75s ease-out;
  -webkit-transition: opacity .75s  ease-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
