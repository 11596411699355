body {
  margin: 0;
  font-family: 'new-order';
  font-weight: 300;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'new-order';
  font-weight: 600;
}
h2, h3, h4 {
  font-family: 'new-order';
  font-weight: 400;
}

p {
  font-family: 'new-order';
  font-weight: 300;
}

button {
  font-family: 'new-order' !important;
  font-size: inherit !important;
  font-weight: 600 !important;
  text-transform: none !important;
}


.swiper {
  width: 100%;
  height: 100%;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
