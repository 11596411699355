@import '../shared-global-styles.module.scss';


$minWebLayoutWidth: 675px;

/* see: https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
.pagenoselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.centercontent {
    text-align: center;
    align-content: center;
}

.page {
    background-color: $color-page-background-darkmode;
    padding-bottom: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    animation: fadeIn 2s;
}

.pagecontent {
    margin: 0 auto;
    max-width: 800px;
    padding: 30px;
    padding-top: 5px;
    padding-bottom: 30px;
    border-bottom: 1px solid $brightPink2Fade;
}

.lastpagecontent {
    @extend .pagecontent;
    border-bottom: none;
}

@media screen and (min-width:$minWebLayoutWidth) {
    // .page {
    //     padding-bottom: 25px;
    // }
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.pageHeader {
    text-align: center;
    font-size: 1.8rem;
    @extend .pagenoselect;
}

.sectionHeader {
    text-align: left;
    font-size: 1.7rem;
    @extend .pagenoselect;
}

.pageText {
    font-size: 1.5rem;
    text-align: center;
    @extend .pagenoselect;
}

.buttonRow {
    width: 100%;
    text-align: center;
    align-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
