$minWebLayoutWidth: 675px;
$brightPink1:rgb(255, 0, 212);
$brightPink2: rgb(215, 0, 180);
$brightPink2Fade: rgb(215, 0, 180, .25);
$lightGrey: rgb(52, 52, 52);
/*Backgrounds*/
$color-background-darkmode: rgb(15, 0, 114) !important;
$color-background-lightmode: rgb(80, 0, 98) !important;

$color-page-background-darkmode: black;
$color-background-2-darkmode: rgb(26, 16, 94) !important;
$color-background-2-lightmode: rgb(144, 0, 255) !important;

$color-title-darkmode: $brightPink1 !important;
$color-title-lightmode: $brightPink1 !important;

$color-text-darkmode: $brightPink2 !important;
$color-border-darkmode: $brightPink2 !important;

$color-no-text-buttons-lightmode: $brightPink1 !important;
$color-subtitle-darkmode: $lightGrey !important;

h1, h2 {
    color: $color-title-darkmode;
}

h3 {
    color: $color-subtitle-darkmode;
}

p {
    color: $color-text-darkmode;
}

.spacerw {
    width: 100%;
}


.button {
    font-size: 1.4em !important;
    border: 2px solid black !important;
    color: black !important;
    border-radius: 0px !important;
    box-shadow: 1px 0px 6px black !important;
}

.transitionFast {
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
}

.transitionSlow {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}