@import '../../shared-global-styles.module.scss';

.buttonextras {
    width: 100%;
    justify-content: right !important;
    text-align: right !important;
    padding: 0px !important;
    padding-right: 20px !important;
}

.buttoncontainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.buttonextras:after {
    clear: both;
    content: "";
}

.menu-box-base {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    transition: all 260ms ease-in-out;
    z-index: 999;
    position: fixed;
    margin-bottom: 30px;
    width: calc(100vw);
    height: calc(100vh);
    display: flex;
    flex-flow: column;
    opacity: 0;
    background-color: $color-background-darkmode;
}

.spacerv {
    display: flex;
    flex-direction: column;
    height: 100%;
}
/* This is from https://stackoverflow.com/questions/23699059/how-to-make-keyframes-start-from-current-property-value */

.menu-box-show {
    margin-left: 0px !important;
    opacity: 1.0;
}

.menu-box-hide {
    margin-left: -100vw !important;
    opacity: 0;
}