@import "../shared-global-styles.module.scss";

.imagebg {
    background-size: cover !important;
    background-position-x: center !important;
    background-position-y: center !important;
    width:100%;
    height: 100%;
    max-width: 600px;
      /* Center horizontally*/
    margin: 0 auto;
    box-shadow: inset 0px 0px 30px 8px black;
    @extend .transitionFast;
}
.mainImage {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  transition: opacity 3s ease-out !important;
  -moz-transition: opacity 3s ease-out !important;
  -webkit-transition: opacity 3s  ease-out !important;
}

.hidden {
  opacity: 0;
}

.imagebgContainer {
    margin-top: 60px;
    background-color: black;
    text-align: center;
    align-content: center;
    width: 100vw;
    height: 60vh;
    max-height: 500px;
    overflow: hidden;
}

.profileContainer {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 100%;
}

.profileImageContainer {
  width: 100%;
  text-align: center;
}
.profileImage {
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: -88px;
  box-shadow: 0px 0px 10px 5px black;
}

.profileHeaderText {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
}
.profileSubtitleText {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 400;
}
.profileText {
  padding: 20px;
  padding-top: 88px;
  border-radius: 20px;
  background-color: rgb(21, 21, 21);
}