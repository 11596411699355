@keyframes fadeIn {
    0% {
        opacity: 0 !important;
    }

    100% {
        opacity: 1 !important;
    }
}


/* see: https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
.noselect {
    /* iOS Safari */
    -webkit-touch-callout: none;
    /* Safari */
    -webkit-user-select: none;
    /* Konqueror HTML */
    -khtml-user-select: none;
    /* Old versions of Firefox */
    -moz-user-select: none;
    /* Internet Explorer/Edge */
    -ms-user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    user-select: none;
}