@use '../shared-component-styles.module.scss';
@import '../../shared-global-styles.module.scss';

.navbar {
    flex-grow: 1;
    flex-direction: row;
    position: fixed;
    left: 0;
    top: 0;
    max-height: 64px;
    min-height: 64px;
    width: 100%;
    min-width: 380px;
    text-align: center;
    box-shadow: 0px 3px 10px black !important;
}

.navbarLight {
    background-color: $color-background-lightmode;
    border-bottom: 1px solid $color-border-darkmode;
    color: black !important;
    @extend .navbar;
}

.navbarDark {
    background-color: $color-background-darkmode;
    border-bottom: 1px solid black;
    @extend .navbar;
}

.toolbar {
    width: 100%;
    max-width: 1100px;
    display: flex;
    margin: 0 auto;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.navheader {
    font-family: 'new-order';
    font-weight: 600;
}

.menubutton {
    font-size: 1.2rem !important;
}

.menubuttonLight {
    color: black !important;
    @extend .menubutton;
}

.menubuttonDark {
    color: whitesmoke !important;
    @extend .menubutton;
}

.menubutton:hover {
    color: rgb(255, 157, 0);
}

.menubox {
    min-width: 200px;
    padding-right: 20px;
}

.logobox {
    padding: 5px;
    padding-top: 10px;
}

.sitelogo {
    padding-left: 20px;
    @extend .noselect;
    cursor: pointer;
    color: inherit;
}

.spacer {
    display: flex;
    flex-grow: 1;
}

.hamburger {
    border: none;
    display: inline-flex;
}

.hamburgerLight {
    color: rgb(23, 21, 71) !important;
    @extend .hamburger;
}

.hamburgerDark {
    color: $color-no-text-buttons-lightmode;
    @extend .hamburger;
}

@media screen and (min-width: $minWebLayoutWidth) {
    .hamburger {
        display: none;
    }
}